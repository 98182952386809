<template>
  <div class="data flex-grow">
    <div class="form-data">
      <div id="logo_name">
        <div>
          <img class="col-sm-6" src="../assets/img/profile/Logo.png" />
        </div>
        <div>
          <label for="dealer_name" class="col-sm-8"> <h5>หงส์ทองผู้กอง</h5> </label>
          <div id="status">กำลังใช้งานอยู่</div>
          <div id="info">
            <div class="form-group row">
              <label for="dealer_id" class="col-sm-2 col-form-label">รหัสตัวแทน</label>
              <div class="col-sm-4">
                <input type="text" readonly class="form-control-plaintext" id="dealer_id" value=" BD00142P" />
              </div>
            </div>
            <div class="form-group row">
              <label for="working_day" class="col-sm-2 col-form-label">วันทำการ</label>
              <div class="col-sm-4">
                <select id="workingdaySelect" name="workingdaySelect">
                  <!-- <option selected>-- วันทำการ --</option> -->
                  <option value="1">วันนี้ 00.00 - 23.59</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <br />
      <div id="storeDetail">
        <div>
          <label for="about_store" class="col-sm-8"> <b>เกี่ยวกับร้าน</b> </label>
          <label for="about_detail" class="col-sm-8">ซื้อสะดวก มั่นใจ ปลอดภัย ใส่ใจทุกการบริการ</label>
        </div>
        <br />
        <div>
          <br />
          <label for="bank_store" class="col-sm-8"> <b>ข้อมูลธนาคาร</b> </label>
          <div id="bank_detail">
            <div>
              <div class="form-group row">
                <label for="dealer_bank" class="col-sm-2 col-form-label head">ชื่อธนาคาร</label>
                <div class="col-sm-4">
                  <input type="text" readonly class="form-control-plaintext" id="dealer_bank" value=" ธนาคารกสิกรไทย" />
                </div>
              </div>
              <div class="form-group row">
                <label for="dealer_Baccount" class="col-sm-2 col-form-label head">ชื่อบัญชี</label>
                <div class="col-sm-2">
                  <input type="text" readonly class="form-control-plaintext" id="dealer_Baccount" value=" xxx x xxxxx x" />
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="dealer_Baccount_number" class="col-sm-2 col-form-label head">เลขบัญชี</label>
              <div class="col-sm-2">
                <input type="text" readonly class="form-control-plaintext" id="dealer_Baccount_number" value="มั่งมี ศรีสุข" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <br />
          <label for="contact_store" class="col-sm-8"><b>ช่องทางการติดต่อ</b></label>
          <div id="contact_detail">
            <div>
              <div class="form-group row">
                <label for="tel_num" class="col-sm-2 col-form-label head">เบอร์โทร</label>
                <div class="col-sm-4">
                  <input type="text" readonly class="form-control-plaintext" id="tel_num" value=" xxx-xxx-xxxx" />
                </div>
              </div>
              <div class="form-group row">
                <label for="line" class="col-sm-2 col-form-label head">ไลน์</label>
                <div class="col-sm-4">
                  <input type="text" readonly class="form-control-plaintext" id="line" value=" @Swan-gold" />
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="facebook" class="col-sm-2 col-form-label head">เฟซบุ๊ค</label>
              <div class="col-sm-6">
                <input type="text" readonly class="form-control-plaintext" id="facebook" value="http://www.facebook/หงส์ทอง" />
              </div>
            </div>
          </div>
        </div>
        <!-- ฟอร์มการซื้อ -->
        <div>
          <img class="col-sm-12 banner" src="../assets/img/Banner1.jpg" />
        </div>
        <div id="menu_bar">
          <nav class="navbar navbar-expand-lg">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavDropdown">
              <ul class="navbar-nav">
                <li class="nav-item active">
                  <router-link to="#">ตะกร้า <span class="sr-only">(current)</span></router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/showhistory">ประวัติการซื้อ</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="#">ค้นหาเลขเด็ด</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/dealer">ค้นหาตัวแทน</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="#">ร้องเรียน</router-link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div id="cart">
          <button type="submit" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#orderModal">ตะกร้าสินค้า</button>
        </div>
        <br />
        <center><h3>กรอกเลขลอตเตอรี่ที่ต้องการค้นหา</h3></center>
        <br />
      </div>
      <form>
        <div class="row input">
          <div class="input-group-lg col-1">
            <input type="text" class="form-control" id="input_1" placeholder="X" maxlength="1" />
          </div>
          <div class="input-group-lg col-1">
            <input type="text" class="form-control" id="input_2" placeholder="X" maxlength="1" />
          </div>
          <div class="input-group-lg col-1">
            <input type="text" class="form-control" id="input_3" placeholder="X" maxlength="1" />
          </div>
          <div class="input-group-lg col-1">
            <input type="text" class="form-control" id="input_4" placeholder="X" maxlength="1" />
          </div>
          <div class="input-group-lg col-1">
            <input type="text" class="form-control" id="input_5" placeholder="X" maxlength="1" />
          </div>
          <div class="input-group-lg col-1">
            <input type="text" class="form-control" id="input_6" placeholder="X" maxlength="1" />
          </div>
        </div>
        <br />
        <div class="col-sm-12 radio">
          <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" @click="twodigits" />
          <label class="btn btn-outline-success" id="btn1" for="btnradio1">เลขท้าย 2 ตัว</label>
          <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" @click="threedigits" />
          <label class="btn btn-outline-success" id="btn2" for="btnradio2">เลขท้าย 3 ตัว</label>
          <input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off" @click="threefrontdigits" />
          <label class="btn btn-outline-success" id="btn3" for="btnradio3">เลขหน้า 3 ตัว</label>
        </div>
        <div id="button">
          <button type="reset" class="btn btn-success" style="margin-right: 15px" @click="resetinput">ลบตัวเลข</button>
          <button type="submit" class="btn btn-outline-success">ค้นหาตัวเลข</button>
        </div>
      </form>
      <div id="catalog">
        <div class="col-sm-11 img">
          <img src="../assets/images/lottery-example.jpg" />
          <div id="catalogbtn" >
            <button class="btn btn-success">เพิ่มลงตะกร้า</button>
          </div>
        </div>
        <div class="col-sm-11 img">
          <img src="../assets/images/lottery-example.jpg" />
          <div id="catalogbtn">
            <button class="btn btn-success">เพิ่มลงตะกร้า</button>
          </div>
        </div>
        <div class="col-sm-11 img">
          <img src="../assets/images/lottery-example.jpg" />
          <div id="catalogbtn">
            <button class="btn btn-success">เพิ่มลงตะกร้า</button>
          </div>
        </div>
        <div class="col-sm-11 img">
          <img src="../assets/images/lottery-example.jpg" />
          <div id="catalogbtn">
            <button class="btn btn-success">เพิ่มลงตะกร้า</button>
          </div>
        </div>
      </div>
      <Order />
      <Customerdetail />
      <Invoice />
    </div>
  </div>
</template>

<script>
import Order from '@/components/cart/order'
import Customerdetail from '@/components/cart/customer-detail'
import Invoice from '@/components/cart/invoice'

export default {
  name: 'DealerStore',
  components: {
    Order,
    Customerdetail,
    Invoice
  },
  data() {},
  methods: {
    twodigits: function () {
      document.getElementById('input_1').disabled = true
      document.getElementById('input_2').disabled = true
      document.getElementById('input_3').disabled = true
      document.getElementById('input_4').disabled = true
      document.getElementById('input_5').disabled = false
      document.getElementById('input_6').disabled = false
    },
    threedigits: function () {
      document.getElementById('input_1').disabled = true
      document.getElementById('input_2').disabled = true
      document.getElementById('input_3').disabled = true
      document.getElementById('input_4').disabled = false
      document.getElementById('input_5').disabled = false
      document.getElementById('input_6').disabled = false
    },
    threefrontdigits: function () {
      document.getElementById('input_1').disabled = false
      document.getElementById('input_2').disabled = false
      document.getElementById('input_3').disabled = false
      document.getElementById('input_4').disabled = true
      document.getElementById('input_5').disabled = true
      document.getElementById('input_6').disabled = true
    },
    resetinput: function () {
      document.getElementById('input_1').disabled = false
      document.getElementById('input_2').disabled = false
      document.getElementById('input_3').disabled = false
      document.getElementById('input_4').disabled = false
      document.getElementById('input_5').disabled = false
      document.getElementById('input_6').disabled = false
    }
  }
}
</script>

<style>
.data {
  display: flex;
  background: whitesmoke;
  justify-content: center;
}
.form-data {
  width: 1200px;
  background: whitesmoke;
  padding: 55px;
}
#logo_name {
  display: grid;
  grid-template-columns: 30% 70%;
}
#status,
.head {
  color: #63c289;
}
#workingdaySelect {
  background-color: #b7e9cb;
}
hr {
  color: #07923f;
  border: solid 1px;
}
#bank_detail {
  display: grid;
  grid-template-columns: auto auto;
}
#contact_detail {
  display: grid;
  grid-template-columns: auto auto;
}
.banner {
  margin-top: 35px;
}
#menu_bar {
  background-color: #63c289;
  margin-top: 25px;
  border-end-end-radius: 35px;
  border-end-start-radius: 35px;
}
#menu_bar li {
  padding: 0 65px;
}
#menu_bar a {
  color: #fff;
  text-decoration: none;
}
.input {
  display: flex;
  justify-content: center;
}
.input input {
  text-align: center;
}
.radio {
  display: flex;
  justify-content: center;
}
#btn1,
#btn2,
#btn3 {
  margin-top: 15px;
  width: 175px;
  padding: 10px;
}
#button {
  text-align: center;
  margin: 35px auto;
}
#catalog {
  display: grid;
  grid-template-columns: auto auto auto auto;
}
#catalog .img {
  border-radius: 25px;
  border: solid 2px #63c289;
}
#catalog img {
  padding: 10px;
}
#catalogbtn,
#catalogbtn button, #button button {
  text-align: center;
  padding: 10px;
  border-radius: 25px;
}
#button button{
  width: 200px;
}
#catalogbtn button{
  width: 100%;
}
#cart {
  padding: 1%;
  text-align: right;
}
</style>