<template>
  <div class="modal fade" id="orderModal" tabindex="-1" aria-labelledby="orderModal" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="mx-auto"><button type="button" data-bs-dismiss="modal">X</button> ปิดหน้านี้</div>
        </div>
        <div class="modal-body">
          <div class="container">
            <div id="head-cart">
              <div>
                <b>รายละเอียดสั่งซื้อลอตเตอรี่</b>
              </div>
              <div>
                <p>ราคาใบละ 80 บาท</p>
              </div>
            </div>
            <div id="detail-cart">
              <h3>รายการนี้จะหมดเวลาใน 05.58 นาที</h3>
              <table>
                <thead>
                  <th class="col-2">หมายเลข</th>
                  <th class="col-1">จำนวน</th>
                  <th class="col-1">ราคา</th>
                  <th class="col-2">ค่าบริการ</th>
                  <th class="col-1">รวม</th>
                  <th class="col-1"></th>
                </thead>
                <tbody>
                  <td>180373</td>
                  <td>1</td>
                  <td>80</td>
                  <td>20</td>
                  <td>100</td>
                  <td>ลบ</td>
                </tbody>
                <tbody>
                  <td>200630</td>
                  <td>1</td>
                  <td>80</td>
                  <td>20</td>
                  <td>100</td>
                  <td>ลบ</td>
                </tbody>
                <tbody>
                  <td>326588</td>
                  <td>1</td>
                  <td>80</td>
                  <td>20</td>
                  <td>100</td>
                  <td>ลบ</td>
                </tbody>
                <tbody>
                  <td><h5>ราคารวม</h5></td>
                  <td><h5>3</h5></td>
                  <td></td>
                  <td></td>
                  <td><h5>300</h5></td>
                </tbody>
              </table>
              <button type="sumbit" class="btn col-sm-12 success" data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#customerModal">ดำเนินการต่อ</button>
              <a href="#" class="btn col-sm-12 add">ซื้อเพิ่ม</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Order'
}
</script>
<style scoped>
#head-cart {
  display: grid;
  grid-template-columns: auto auto;
}
#detail-cart h3 {
  color:  #fb5c62;
}
#detail-cart table h5 {
  color: #63c289;
}
.btn {
    border-radius: 35px;
}
.success {
  background-color: #63c289;
  color: #fff;
  margin: 15px 0 ;
}
.add {
  background-color: #6c6c6c;
  color: #fff;
}
th, td {
    padding: 1%;
}
.modal-header {
    background-color: #6c6c6c;
    color: #fff;
}
</style>
