<template>
  <div class="modal fade" id="invoiceModal" tabindex="-1" aria-labelledby="invoiceModal" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header"></div>
        <div class="modal-body">
          <div class="container">
            <div id="head-invoice">
              <div>
                <b> <h5>รายละเอียดสั่งซื้อ</h5> </b>
              </div>

              <div>
                <center><h2>การจองสำเร็จ</h2></center>
                <br />
                <center>การสั่งซื้อจะสำเร็จหลังจากที่ตัวแทนได้รับเงินและอนุมัติการสั่งซื้อจากระบบแล้ว</center>
              </div>
            </div>
            <div id="detail-invoice">
              <br />
              <div id="head-invoice-detail">
                <div>
                  <b>หมายเลขออเดอร์</b>
                </div>
                <div style="text-align: right">
                  <h4>07586811521071</h4>
                  สถานะ <span>รอตรวจสอบการชำระเงิน</span>
                </div>
              </div>
              <br />
              <table>
                <thead>
                  <th class="col-2">หมายเลข</th>
                  <th class="col-1">จำนวน</th>
                  <th class="col-1" id="status-">ไม่สำเร็จ</th>
                  <th class="col-1">ราคา</th>
                  <th class="col-1">ค่าบริการ</th>
                  <th class="col-1">รวม</th>
                </thead>
                <tbody>
                  <td>180373</td>
                  <td>1</td>
                  <td id="status-">0</td>
                  <td>80</td>
                  <td>20</td>
                  <td>100</td>
                </tbody>
                <tbody>
                  <td><h5>ราคารวม</h5></td>
                  <td><h5>1</h5></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td><h5>100</h5></td>
                </tbody>
              </table>
              <br />
              <div id="upload-slip">
                <div class="container">
                  <center><h3>อัพโหลดสลิป เพื่อยืนยันการสั่งซื้อให้เสร็จสมบูรณ์</h3></center>
                  <center><b>คำเตือน</b></center>
                  <p>
                    * หากผู้สั่งซื้อล็อตเตอรี่ ปลอมแปลงเอกสารรายละเอียดการชำระเงิน(สลิปปลอม) หากทางบริษัทหรือตัวแทนตรวจพบ ล็อตเตอรี่ใบนั้นจะถูกยกเลิกการสั่งซื้อ
                    และสิทธิ์จะขึ้นอยู่กับดุลพินิจของทางบริษัท
                  </p>
                  <p>* ผู้ที่มีเจตนาฉ้อโกง ทางบริษัทจะส่งเรื่องให้เจ้าหน้าที่ดำเนินการความผิดตามกฎหมายต่อไป</p>
                  <center>
                    <b>กรุณาชำระเงิน และอัพโหลดสลิปภายใน</b>
                    <h4 style="color: #fb5c62">00:58:36</h4>
                    ช่องทางชำระเงิน
                  </center>
                  <button id="bank" class="col-sm-12">บัญชีธนาคาร</button>
                  <center>
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label">ธนาคาร</label>
                      <div class="col-sm-6">
                        <input type="text" readonly class="form-control-plaintext" id="bank_name" value="ธนาคารไทยพาณิชย์" />
                      </div>
                    </div>
                    <hr />
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label">ชื่อบัญชี</label>
                      <div class="col-sm-6">
                        <input type="text" readonly class="form-control-plaintext" id="bank_accound_name" value="บริษัท หงส์ทองผู้กอง จำกัด" />
                      </div>
                    </div>
                    <hr />
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label">เลขบัญชี</label>
                      <div class="col-sm-6">
                        <input type="text" readonly class="form-control-plaintext" id="bank_number" value="xxx x xxxxx x" />
                      </div>
                    </div>
                    <hr />
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label">จำนวนเงิน</label>
                      <div class="col-sm-6">
                        <input type="text" readonly class="form-control-plaintext" id="bank_amount" value="100 บาท" />
                      </div>
                    </div>
                    <hr />
                    <br />
                    <b>รองรับนามสกุลไฟล์ .jpg .png .jpeg ขนาดไม่เกิน 10 mb</b>
                    <form action="#">
                      <br />
                      <input type="file" class="btn btn-secondary" />
                    </form>
                    <a href="#">ไม่สามารถอัพโหลดสลิปได้?</a>
                  </center>
                </div>
              </div>
              <div id="info">
                <div id="customer">
                  <label style="color: #63c289"><b>ข้อมูลผู้ซื้อ</b></label>
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">ชื่อ</label>
                    <div class="col-sm-6">
                      <input type="text" readonly class="form-control-plaintext" id="customer_name" value="มารวย มั่งมี" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">เบอร์โทร</label>
                    <div class="col-sm-6">
                      <input type="text" readonly class="form-control-plaintext" id="customer_tel" value="xxx xxx xxxx" />
                    </div>
                  </div>
                </div>
                <hr />
                <div id="dealer">
                  <label style="color: #63c289"> <b>ข้อมูลผู้ขาย</b> </label>
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">ชื่อ</label>
                    <div class="col-sm-6">
                      <input type="text" readonly class="form-control-plaintext" id="dealer_name" value="มารวย มั่งมี" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">รหัสตัวแทน</label>
                    <div class="col-sm-6">
                      <input type="text" readonly class="form-control-plaintext" id="dealer_id" value="BD00086P" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">ธนาคาร</label>
                    <div class="col-sm-6">
                      <input type="text" readonly class="form-control-plaintext" id="dealer_bank" value="ธนาคารไทยพาณิชย์" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label" style="color: #63c289">ชื่อบัญชี</label>
                    <div class="col-sm-6">
                      <input type="text" readonly class="form-control-plaintext" id="dealer_bank_account" value="บริษัท หงส์ทองผู้กอง จำกัด" style="color: #63c289" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">เลขบัญชี</label>
                    <div class="col-sm-6">
                      <input type="text" readonly class="form-control-plaintext" id="dealer_bank_number" value="xxx x xxxxx x" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">เบอร์โทร</label>
                    <div class="col-sm-6">
                      <input type="text" readonly class="form-control-plaintext" id="dealer_tel" value="xxx xxx xxxx" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">ไอดีไลน์</label>
                    <div class="col-sm-6">
                      <input type="text" readonly class="form-control-plaintext" id="dealer_line" value="@Swan-gold" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">เฟซบุ๊ค</label>
                    <div class="col-sm-6">
                      <input type="text" readonly class="form-control-plaintext" id="dealer_facebook" value="http://www.facebook/หงส์ทอง" />
                    </div>
                  </div>
                </div>
                <br />
                <button type="button" class="btn close col-sm-12" data-bs-dismiss="modal">X ปิดหน้านี้</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Invoice'
}
</script>
<style scoped>
h3,
h2,
h4 {
  color: #63c289;
}
span {
  background-color: #63c289;
  color: #fff;
  padding: 1%;
  border-radius: 35px;
}
#head-invoice-detail {
  display: grid;
  grid-template-columns: auto auto;
}
#status- {
  color: #fb5c62;
}
th,
td {
  padding: 1%;
}
#detail-invoice table h5 {
  color: #63c289;
}
#upload-slip {
  border: 1px solid lightgrey;
  border-radius: 15px;
  box-shadow: 3px 3px 20px 3px lightgray;
  padding: 3%;
}
#upload-slip p {
  padding: 0 12%;
}
button,
.btn {
  border-radius: 35px;
}
.close {
  background-color: #fb5c62;
  color: #fff;
}
#bank {
  background-color: #63c289;
  color: #fff;
  margin: 10px auto;
  padding: 10px;
}
a {
  color: #63c289;
}
#info {
  margin: 45px auto;
}
</style>
