<template>
  <div class="modal fade" id="customerModal" tabindex="-1" aria-labelledby="customerModal" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <div class="mx-auto"><button type="button" data-bs-dismiss="modal">X</button> ปิดหน้านี้</div>
        </div>
        <div class="modal-body">
          <div class="container">
            <div id="head-info">
              <div>
                <b><h3>ข้อมูลผู้ซื้อ</h3></b>
              </div>
              <div>
                <h5>โปรดตรวจสอบ หรือแก้ไขข้อมูลเผื่อดำเนินการต่อ</h5>
                <h5>เบอร์โทรศัพท์ของคุณคือ xxx-xxx-xxxx</h5>
              </div>
            </div>
            <div id="detail-info">
              <h3>รายการนี้จะหมดเวลาใน 02.03 นาที</h3>
            </div>
            <div id="customer-data">
              <!-- <form> -->
              <div id="form">
                <div class="col" id="head-form1">
                  <label> ชื่อ และนามสกุล ที่ท่านกรอกดังต่อไปนี้จะนำไปใช้สำหรับอ้างอิง <u>การขึ้นรางวัล</u> </label>
                </div>
                <div id="form-detail">
                  <div class="form-group col">
                    <label>ชื่อ</label>
                    <input type="text" class="form-control" id="fname" />
                  </div>
                  <div class="form-group col">
                    <label>นามสกุล</label>
                    <input type="text" class="form-control" id="lname" />
                  </div>
                </div>
              </div>
              <div id="form">
                <div class="col" id="head-form">
                  <label> ผูกบัญชีรับเงินเมื่อกถูกรางวัล </label>
                </div>
                <div id="form-detail">
                  <div class="form-group col">
                    <label>ธนาคาร</label>
                    <select class="form-select" name="bank_name">
                      <option selected>-- เลือกธนาคาร --</option>
                      <option value="SCB">SCB</option>
                      <option value="KBANK">KBANK</option>
                    </select>
                  </div>
                  <div class="form-group col">
                    <label>ชื่อบัญชี</label>
                    <input type="text" class="form-control" id="lname" />
                  </div>
                  <div class="form-group col">
                    <label>เลขบัญชี</label>
                    <input type="text" class="form-control" id="lname" />
                  </div>
                </div>
              </div>
              <div id="form">
                <div class="col" id="head-form">
                  <label> ข้อมูลติดต่อ </label>
                </div>
                <div id="form-detail">
                  <div class="form-group col">
                    <label>เฟซบุ๊ค(ถ้ามี)</label>
                    <input type="text" class="form-control" id="fname" />
                  </div>
                  <div class="form-group col">
                    <label>ไลน์(ถ้ามี)</label>
                    <input type="text" class="form-control" id="lname" />
                  </div>
                </div>
              </div>
              <button type="submit" class="btn col-12" data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#invoiceModal">ยืนยันการสั่งซื้อและชำระเงิน</button>
              <!-- </form> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Customerdetail'
}
</script>
<style scoped>
.modal-header {
  background-color: #6c6c6c;
  color: #fff;
}
#head-info {
  text-align: center;
}
#head-info h3 {
  color: #63c289;
}
#detail-info h3 {
  color: #fb5c62;
  text-align: center;
}
#form {
  border-radius: 15px;
  border: solid lightgray 1px;
  box-shadow: 2px 2px 5px lightgray;
  margin: 20px;
}
#head-form1 {
  color: #fff;
  font-size: 20px;
  padding: 15px;
  background-color: #fb5c62;
  border-start-start-radius: 15px;
  border-start-end-radius: 15px;
}
#head-form {
  color: #fff;
  font-size: 20px;
  padding: 15px;
  background-color: #63c289;
  border-start-start-radius: 15px;
  border-start-end-radius: 15px;
}
#form #form-detail {
  padding: 10px;
}
input,
select {
  background-color: #b7e9cb;
}
.btn {
  border-radius: 35px;
  background-color: #63c289;
  color: #fff;
  margin: 15px 0;
  padding: 5px;
}
</style>
